var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "portal",
    { attrs: { to: "main-popup" } },
    [
      _c(
        "popup",
        {
          attrs: { "content-class": "signup-modal" },
          on: {
            input: function ($event) {
              return _vm.$emit("close")
            },
          },
          model: {
            value: _vm.isShown,
            callback: function ($$v) {
              _vm.isShown = $$v
            },
            expression: "isShown",
          },
        },
        [
          _c("div", { staticClass: "signup" }, [
            _c("h3", { staticClass: "signup-title" }, [
              _vm._v(" Enter your username to participate in the tournament "),
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username",
                },
              ],
              staticClass: "signup-input",
              attrs: { type: "text", placeholder: "username" },
              domProps: { value: _vm.username },
              on: {
                keypress: _vm.validate,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.username = $event.target.value
                },
              },
            }),
            _c(
              "div",
              { staticClass: "signup-action" },
              [
                _c("btn", {
                  attrs: {
                    type: "mint-dark",
                    text: "CONTINUE",
                    disabled: !_vm.username.length,
                  },
                  on: { click: _vm.onConfirmClick },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }