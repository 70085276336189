<template>
    <div class="game">
        <Preloader v-show="isLoading" translucent/>
        <div v-show="!isMobile" class="game__banner">
            <a
                :href="mainBanner?.url"
                class=""
                target="_blank"
                >
                <div
                    class="game__banner-inner"
                >
                    <picture>
                    <img
                        :src="mainBanner?.narrow_image"
                        alt="narrow image banner"
                        class="game__banner-image"
                    >
                    </picture>
                </div>
            </a>
        </div>
        <GetTokensPopup ref="getTokensPopup" />
        <div v-show="!isMobile">
            <template v-if="isTournamentActive">
                <btn
                    v-if="joinBtnShowed"
                    :type="'mint-dark'"
                    class="approve-popup__buttons-item"
                    text="JOIN TOURNAMENT"
                    @click="joinTournamentClick"
                />
                <div v-else-if="readyForTournament" class="tournament-label">Joined tournament as {{ username }}</div>

                <SignUpPopup
                    v-if="isSignUpModalOpen"
                    @close="signUpModalClose"
                    v-model="isLoading"
                />
            </template>

                <iframe src="/game-body" width="900px" height="648px" ref="gameBody" /> 
        </div>
        <div v-show="isMobile" style="text-align:center;max-width:90%;font-weight:bold;margin: 0 auto;">
            <h1>The game is not available on the mobile version of the site. </h1>
        </div>

        <div class="game__description">
            <span class="game__description-title">Game requirements:</span>
            <ul class="list">
                <li class="list-element">
                    Browser - Google Chrome, Mozilla Firefox, Apple Safari, Microsoft Edge based on Chromium (Legacy not
                    supported)
                </li>
                <li class="list-element">
                    No proxy
                </li>
            </ul>
        </div>
    </div>
</template>

<script>

import tournamentMixin from "@/mixins/tournamentMixin";
import SignUpPopup from "@/components/popups/signup-popup";
import {mapGetters, mapActions} from "vuex";
import GetTokensPopup from '@/components/popups/get-tokens-popup';

export default {
    name: "Game",
    mixins: [tournamentMixin],
    components: {
        SignUpPopup,
        GetTokensPopup,
    },
    data() {
        return {
            isLoading: false,
        };
    },
    watch: {
        account() {
            this.sendDataToGame();
        },
        isCorrectNetwork() {
            this.sendDataToGame();
        },
        token() {
            this.sendAccessTokenToGame();
        },
        isSignUpModalOpen(val) {
            this.sendModalStateToGame(val);
        },
        authToken() {
            this.sendAccessTokenToGame();
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth < 500
        },
        ...mapGetters(['account']),
        ...mapGetters('contract', ['isCorrectNetwork']),
        ...mapGetters('events', {
            mainBanner: 'GET_INFO_BANNER'
        }),

        progressPercent() {
            return (100 * this.progress).toFixed(2)
        },

        isSplashShowed() {
            return this.progress !== 1
        },

        correctAccount() {
            return this.isCorrectNetwork ? this.account : '';
        },
        authToken() {
            return this.$store.getters['auth/token'];
        },
    },
    created() {
        this.$router.history.listen((newLocation) => {
            window.location.href = newLocation.path;
        })
    },
    mounted() {
        this.REQUEST_INFO_BANNER();
        this.$refs.gameBody.addEventListener('load', ()=>{
            this.sendDataToGame();
        })

        window.addEventListener('message', this.handleMessages, false);
    },
    unmounted() {
        window.removeEventListener('message', this.handleMessages);
    },
    methods: {
        ...mapActions('events', ['REQUEST_INFO_BANNER']),
        getWalletAddress() {
            return {
                address: this.correctAccount,
                readyForTournament: !!this.readyForTournament,
            }
        },
        sendDataToGame() {
            const data = this.getWalletAddress();
            this.$refs.gameBody.contentWindow.postMessage({eventType: "sendDataToUnity", ...data})
        },
        sendAccessTokenToGame() {
            const data = {
                token: this.token,
                authToken: this.authToken,
            }
            this.$refs.gameBody.contentWindow.postMessage({eventType: "sendAccessTokenToUnity", ...data})
        },
        sendModalStateToGame(data) {
            this.$refs.gameBody.contentWindow.postMessage({eventType: "sendModalStateToUnity", ...data})
        },
        openGetCreditsWindow() {
            this.$refs.getTokensPopup.open();
        },
        handleMessages(e) {
            if (e.data === 'openGetCreditsWindow') {
                this.openGetCreditsWindow();
            }
        }
    }
};
</script>

<style lang="scss">
.game {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 105px;
    padding-bottom: 105px;
    // height: 75vh;
    position: relative;

    background: rgb(255, 91, 2);
    background: linear-gradient(180deg, rgba(255, 91, 2, 1) 35%, rgba(255, 168, 8, 1) 100%);

    &__description {
        margin-top: 3em;
        max-width: 90%;
        font-weight: bold;
        text-align: left;

        &-title {
            margin-top: 0.5em;
        }

        .list {
            margin-top: 0.5em;

            &-element {
                margin-left: 1.5em;
                list-style-type: inherit;
            }
        }

    }

    &-footer {
        display: flex;
        justify-content: flex-end;

        .expand {
            margin-top: 0.3em;
            padding: 0.4em;
            width: 2em;
            height: 2em;
            background: white;
            cursor: pointer;
        }
    }

    &__banner {
        width: 100%;
        max-width: 900px;
        max-height: 190px;
        margin-bottom: 40px;
        padding-left: 0;
        padding-right: 0;
    }

    &__banner-inner {
        border: 3px solid #fff;
    }

    &__banner-image {
        display: block;
        object-fit: cover;
        width: 100%;
        max-height: 100%;
    }
}

.tournament-label {
    background: #000000;
    color: white;
    padding: 1em;
}

.webgl-content * {
    border: 0;
    margin: 0;
    padding: 0;
}

@media screen and (max-width: ($bp_desktop - 1px)) {
    .game {
        &__banner {
            max-width: unset;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
</style>
