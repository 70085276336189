// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".game {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding-top: 105px;\n  padding-bottom: 105px;\n  position: relative;\n  background: #ff5b02;\n  background: linear-gradient(180deg, #ff5b02 35%, #ffa808 100%);\n}\n.game__description {\n  margin-top: 3em;\n  max-width: 90%;\n  font-weight: bold;\n  text-align: left;\n}\n.game__description-title {\n  margin-top: 0.5em;\n}\n.game__description .list {\n  margin-top: 0.5em;\n}\n.game__description .list-element {\n  margin-left: 1.5em;\n  list-style-type: inherit;\n}\n.game-footer {\n  display: flex;\n  justify-content: flex-end;\n}\n.game-footer .expand {\n  margin-top: 0.3em;\n  padding: 0.4em;\n  width: 2em;\n  height: 2em;\n  background: white;\n  cursor: pointer;\n}\n.game__banner {\n  width: 100%;\n  max-width: 900px;\n  max-height: 190px;\n  margin-bottom: 40px;\n  padding-left: 0;\n  padding-right: 0;\n}\n.game__banner-inner {\n  border: 3px solid #fff;\n}\n.game__banner-image {\n  display: block;\n  -o-object-fit: cover;\n     object-fit: cover;\n  width: 100%;\n  max-height: 100%;\n}\n.tournament-label {\n  background: #000000;\n  color: white;\n  padding: 1em;\n}\n.webgl-content * {\n  border: 0;\n  margin: 0;\n  padding: 0;\n}\n@media screen and (max-width: 991px) {\n.game__banner {\n    max-width: unset;\n    padding-left: 15px;\n    padding-right: 15px;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
