// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".signup-modal {\n  padding: 70px 25px 40px 25px;\n}\n.signup {\n  min-width: 320px;\n  text-align: center;\n}\n.signup-title {\n  font-weight: 600;\n  font-size: 20px;\n  text-align: center;\n}\n.signup-input {\n  background: #f0f0f0;\n  border: 1px solid #b9b9b9;\n  padding: 0.5em;\n  margin-top: 1.5em;\n}\n.signup-action {\n  width: 215px;\n  margin: 1.5em auto;\n}\n.signup-action button {\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
