<template>
    <portal to="main-popup">
        <popup v-model="isShown" :content-class="'signup-modal'" @input="$emit('close')">
            <div class="signup">
                <h3 class="signup-title">
                    Enter your username to participate in the tournament
                </h3>

                <input
                    class="signup-input"
                    type="text"
                    placeholder="username"
                    v-model="username"
                    @keypress="validate"
                />

                <div class="signup-action">
                    <btn
                        type="mint-dark"
                        :text="'CONTINUE'"
                        :disabled="!username.length"
                        @click="onConfirmClick"
                    />
                </div>
            </div>
        </popup>
    </portal>
</template>

<script>
import {mapActions} from "vuex";

export default {
    name: "SignUpPopup",
    props: {
        value: { type: Boolean, default: false },
    },
    data() {
        return {
            isShown: false,
            username: '',
        }
    },
    mounted() {
        this.isShown = true;
    },
    methods: {
        ...mapActions('tournament', ['updateProfile']),

        async onConfirmClick() {
            this.$emit('input', true);

            try {
                await this.updateProfile(this.username);
            } finally {
                this.$emit('input', false);
                this.$emit('close');
            }
        },
        validate(event) {
            if (!/^[a-zA-Z0-9]*$/g.test(event.key)) {
                event.preventDefault();
            }
        },
    },
}
</script>

<style lang="scss">
.signup-modal {
    padding: 70px 25px 40px 25px;
}

.signup {
    min-width: 320px;
    text-align: center;

    &-title {
        font-weight: 600;
        font-size: 20px;
        text-align: center;
    }
    &-input {
        background: #f0f0f0;
        border: 1px solid #b9b9b9;
        padding: 0.5em;
        margin-top: 1.5em;
    }
    &-action {
        width: 215px;
        margin: 1.5em auto;

        button {
            width: 100%;
        }
    }

}

</style>
