import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            isLoading: false,
            isTournamentActive: false,
            isSignUpModalOpen: false,
        }
    },
    watch: {
        readyForTournament() {
            this.sendDataToUnity();
        },
        account() {
            this.clearData();
        },
        isLoggedIn(val) {
            if (!val) {
                this.clearData();
            }
        },
        isUnityLoaded(val) {
            if (val) {
                this.sendDataToUnity();
                this.sendAccesTokenToUnity();
            }
        },
    },
    computed: {
        ...mapGetters('contract', ['isCorrectNetwork', 'contractService']),
        ...mapGetters(['account', 'isLoggedIn']),
        ...mapGetters('tournament', ['token', 'username', 'address']),

        joinBtnShowed() {
            return this.isCorrectNetwork && (!this.username || !this.token);
        },
        readyForTournament() {
            return this.username
                && this.address
                && this.token
                && this.isCorrectNetwork
                && this.account === this.address;
        },
    },
    created() {
        if (this.$route.query.tournament2022 === undefined) {
            return;
        }

        this.isTournamentActive = true;
    },
    async mounted() {
        if (this.isTournamentActive && this.account && this.token) {
            await this.checkValidSession();
        }
    },
    methods: {
        ...mapActions('tournament', ['checkValidSession', 'signUpTournament', 'clearData']),

        async joinTournamentClick() {
            if (this.token) {
                this.signUpModalOpen();

                return;
            }

            this.isLoading = true;

            try {
                const message = this.contractService.web3.utils.toHex("tournament2022");
                const signature = await this.contractService.web3.eth.personal.sign(message, this.account);

                await this.signUpTournament({address: this.account, sign: signature});

                if (!this.username) {
                    this.signUpModalOpen();
                }
            } catch (e) {
                this.$eventBus.$emit('errorPopup', '', e.message || 'unexpected error');
            }

            this.isLoading = false;
        },

        signUpModalOpen() {
            this.isSignUpModalOpen = true;
        },
        signUpModalClose() {
            this.isSignUpModalOpen = false;
        },
    },
}
