var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game" },
    [
      _c("Preloader", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLoading,
            expression: "isLoading",
          },
        ],
        attrs: { translucent: "" },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMobile,
              expression: "!isMobile",
            },
          ],
          staticClass: "game__banner",
        },
        [
          _c("a", { attrs: { href: _vm.mainBanner?.url, target: "_blank" } }, [
            _c("div", { staticClass: "game__banner-inner" }, [
              _c("picture", [
                _c("img", {
                  staticClass: "game__banner-image",
                  attrs: {
                    src: _vm.mainBanner?.narrow_image,
                    alt: "narrow image banner",
                  },
                }),
              ]),
            ]),
          ]),
        ]
      ),
      _c("GetTokensPopup", { ref: "getTokensPopup" }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMobile,
              expression: "!isMobile",
            },
          ],
        },
        [
          _vm.isTournamentActive
            ? [
                _vm.joinBtnShowed
                  ? _c("btn", {
                      staticClass: "approve-popup__buttons-item",
                      attrs: { type: "mint-dark", text: "JOIN TOURNAMENT" },
                      on: { click: _vm.joinTournamentClick },
                    })
                  : _vm.readyForTournament
                  ? _c("div", { staticClass: "tournament-label" }, [
                      _vm._v("Joined tournament as " + _vm._s(_vm.username)),
                    ])
                  : _vm._e(),
                _vm.isSignUpModalOpen
                  ? _c("SignUpPopup", {
                      on: { close: _vm.signUpModalClose },
                      model: {
                        value: _vm.isLoading,
                        callback: function ($$v) {
                          _vm.isLoading = $$v
                        },
                        expression: "isLoading",
                      },
                    })
                  : _vm._e(),
              ]
            : _vm._e(),
          _c("iframe", {
            ref: "gameBody",
            attrs: { src: "/game-body", width: "900px", height: "648px" },
          }),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isMobile,
              expression: "isMobile",
            },
          ],
          staticStyle: {
            "text-align": "center",
            "max-width": "90%",
            "font-weight": "bold",
            margin: "0 auto",
          },
        },
        [
          _c("h1", [
            _vm._v(
              "The game is not available on the mobile version of the site. "
            ),
          ]),
        ]
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "game__description" }, [
      _c("span", { staticClass: "game__description-title" }, [
        _vm._v("Game requirements:"),
      ]),
      _c("ul", { staticClass: "list" }, [
        _c("li", { staticClass: "list-element" }, [
          _vm._v(
            " Browser - Google Chrome, Mozilla Firefox, Apple Safari, Microsoft Edge based on Chromium (Legacy not supported) "
          ),
        ]),
        _c("li", { staticClass: "list-element" }, [_vm._v(" No proxy ")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }